.s-tabs-dropdown {
  position: absolute;
  background: #fefefe;
  border: 1px solid black;
  max-height: 200px;
  overflow: auto;
}

.s-tabs-dropdown-hidden {
  display: none;
}

.s-tabs-dropdown-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.s-tabs-dropdown-menu-item {
  padding: 4px 8px;
}

.s-tabs-dropdown-menu-item-selected {
  background: red;
}

.s-tabs-dropdown-menu-item-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.s-tabs-content {
  display: flex;
  width: 100%;
}

.s-tabs-content-holder {
  flex: auto;
}

.s-tabs-content-animated {
  transition: margin 0.3s;
}

.s-tabs-tabpane {
  width: 100%;
  flex: none;
}

.s-tabs {
  display: flex;
}

.s-tabs-top,
.s-tabs-bottom {
  flex-direction: column;
}

.s-tabs-top .s-tabs-ink-bar,
.s-tabs-bottom .s-tabs-ink-bar {
  height: 3px;
}

.s-tabs-top .s-tabs-ink-bar {
  bottom: 0;
}

.s-tabs-bottom .s-tabs-nav {
  order: 1;
}

.s-tabs-bottom .s-tabs-content {
  order: 0;
}

.s-tabs-bottom .s-tabs-ink-bar {
  top: 0;
}

.s-tabs-left.s-tabs-editable .s-tabs-tab,
.s-tabs-right.s-tabs-editable .s-tabs-tab {
  padding-right: 32px;
}

.s-tabs-left .s-tabs-nav-wrap,
.s-tabs-right .s-tabs-nav-wrap {
  flex-direction: column;
}

.s-tabs-left .s-tabs-ink-bar,
.s-tabs-right .s-tabs-ink-bar {
  width: 3px;
}

.s-tabs-left .s-tabs-nav,
.s-tabs-right .s-tabs-nav {
  flex-direction: column;
  min-width: 50px;
}

.s-tabs-left .s-tabs-nav-list,
.s-tabs-right .s-tabs-nav-list {
  flex-direction: column;
}

.s-tabs-left .s-tabs-nav-operations,
.s-tabs-right .s-tabs-nav-operations {
  flex-direction: column;
}

.s-tabs-left .s-tabs-ink-bar {
  right: 0;
}

.s-tabs-right .s-tabs-nav {
  order: 1;
}

.s-tabs-right .s-tabs-content {
  order: 0;
}

.s-tabs-right .s-tabs-ink-bar {
  left: 0;
}

.s-tabs-rtl {
  direction: rtl;
}

.s-tabs-dropdown-rtl {
  direction: rtl;
}

.s-tabs {
  border: none;
  background-color: #fff;
  font-size: 14px;
  overflow: hidden;
}

.s-tabs-nav {
  display: flex;
  flex: none;
  position: relative;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  user-select: none;

  @media screen and(min-width: 1200px) {
    box-shadow: none;
  }
}

.s-tabs-nav-measure,
.s-tabs-nav-wrap {
  transform: translate(0);
  position: relative;
  display: inline-block;
  flex: auto;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
}

.s-tabs-nav-measure-ping-left::before,
.s-tabs-nav-wrap-ping-left::before,
.s-tabs-nav-measure-ping-right::after,
.s-tabs-nav-wrap-ping-right::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
}

.s-tabs-nav-measure-ping-left::before,
.s-tabs-nav-wrap-ping-left::before {
  left: 0;
  // border-left: 1px solid red;
}

.s-tabs-nav-measure-ping-right::after,
.s-tabs-nav-wrap-ping-right::after {
  right: 0;
  // border-right: 1px solid red;
}

.s-tabs-nav-measure-ping-top::before,
.s-tabs-nav-wrap-ping-top::before,
.s-tabs-nav-measure-ping-bottom::after,
.s-tabs-nav-wrap-ping-bottom::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
}

.s-tabs-nav-measure-ping-top::before,
.s-tabs-nav-wrap-ping-top::before {
  top: 0;
  // border-top: 1px solid red;
}

.s-tabs-nav-measure-ping-bottom::after,
.s-tabs-nav-wrap-ping-bottom::after {
  bottom: 0;
  // border-top: 1px solid red;
}

.s-tabs-nav-list {
  display: flex;
  position: relative;
  transition: transform 0.3s;
}

.s-tabs-nav-operations {
  display: flex;
  display: none;
}

.s-tabs-nav-operations-hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}

.s-tabs-nav-more {
  border: 1px solid blue;
  background: rgba(255, 0, 0, 0.1);
}

.s-tabs-nav-add {
  border: 1px solid green;
  background: rgba(0, 255, 0, 0.1);
}

.s-tabs-tab {
  border: 0;
  margin: 0;
  display: flex;
  outline: none;
  cursor: pointer;
  position: relative;
  align-items: center;

  padding: 5px 12px;
  font-weight: 700;
  font-size: 13px;
  color: #a6becd;
  background: white;
  text-transform: capitalize;
}

.s-tabs-tab-btn,
.s-tabs-tab-remove {
  border: 0;
  background: transparent;
}

.s-tabs-tab-btn {
  font-weight: inherit;
  line-height: 32px;
  outline: none;
}

.s-tabs-tab-remove:hover {
  color: red;
}

.s-tabs-tab-active {
  font-weight: 700;
  font-size: 13px;
  color: #424d55;
}

.s-tabs-ink-bar {
  position: absolute;
  background: #37e2d1 !important;
  pointer-events: none;
}

.s-tabs-ink-bar-animated {
  transition: all 0.3s;
}

.s-tabs-extra-content {
  flex: none;
}

.s-tabs {
  &History {
    .s-tabs-nav-list {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      background-color: red;

      .s-tabs-tab {
        display: flex;
        place-content: center;
      }
    }
  }
}
