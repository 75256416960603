// change font face
@font-face {
  font-family: 'rsuite-icon-font';
  src: url('https://cdn.skul.id/assets/npm/rsuite/dist/styles/fonts/rsuite-icon-font.ttf')
      format('truetype'),
    url('https://cdn.skul.id/assets/npm/rsuite/dist/styles/fonts/rsuite-icon-font.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}
// breaking rule number 5
// need to delete after use new button component
.rs-btn {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  font-size: 1rem;
  height: 55px;

  &.rs-btn-primary {
    color: #ffffff;
    background: linear-gradient(91.96deg, #3ee6d5 -13.02%, #0ecab8 100%);

    &:active {
      background: linear-gradient(91.96deg, #3ee6d5 -13.02%, #0ecab8 100%);
    }

    &.rs-btn-disabled:hover,
    &.rs-btn-disabled:focus,
    .rs-btn-disabled.focus {
      background: linear-gradient(91.96deg, #3ee6d5 -13.02%, #0ecab8 100%);
    }
  }
}

// breaking rule number 5
.rs-uploader-picture .rs-uploader-trigger-btn {
  border-radius: 100%;
  border: none;
}

// breaking rule number 5
.rs-control-label {
  margin-bottom: 6px;
}

// breaking rule number 5
.rs-message {
  font-size: 0.85rem;
}

// breaking rule number 5
.rs-message .rs-message-icon-wrapper {
  top: 18px;
}

.rs-input-group-addon {
  background-color: $light-gray;
  color: #677782;

  &:first-child {
    padding-right: 0px;
  }

  &:last-child {
    padding-left: 0px;
  }
}

.rs-input-group-addon:first-child {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.rs-input-group:not(.rs-input-group-inside) {
  border: 1px solid $light-gray;
}

.rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled):hover,
.rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled).rs-input-group-focus {
  border-color: #3ee6d5;
}

// breaking rule number 5
.rs-input {
  background-color: $light-gray;
  // border: $light-gray !important;
  padding: 14px 10px;
  font-size: 1rem;
}

.rs-input[disabled] {
  background-color: $light-gray;
  color: #575757;
}

.rs-input-group:not(.rs-input-group-inside) {
  border-radius: 6px;
}

.rs-input-group-btn {
  background-color: $light-gray;

  &:hover,
  &:focus {
    background-color: $light-gray;
  }
}

// checkbox custom rsuite global css
.rs-checkbox-checker:hover .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border-color: #ed5278;
}

.rs-checkbox-checked .rs-checkbox-wrapper [type='checkbox']:focus ~ .rs-checkbox-inner::before {
  box-shadow: none;
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border-color: #e94e69;
  background-color: #e94e69;
}

// radio custom rsuite global css
.rs-radio-checked .rs-radio-wrapper .rs-radio-inner::before {
  border-color: #e8285c;
  background-color: #e8285c;
}

.rs-radio-checker:hover .rs-radio-wrapper .rs-radio-inner::before {
  border-color: #e8285c;
}

// calender picker custom rsuite global css
.rs-picker-menu {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
}
.rs-picker-toolbar-option {
  color: #14cebc;

  &:hover,
  &:focus {
    color: #14cebc;
  }
}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  width: 32px;
  height: 32px;
  padding: 0;
  line-height: 32px;
}

.rs-calendar-table-header-row .rs-calendar-table-cell-content {
  color: inherit;
  font-weight: bold;
}

.rs-calendar-header-title {
  color: inherit;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background: linear-gradient(91.96deg, #3ee6d5 -13.02%, #0ecab8 100%) !important;
  border: none !important;
  font-weight: bold;
}

.rs-picker-toolbar-right-btn-ok {
  background: linear-gradient(91.96deg, #3ee6d5 -13.02%, #0ecab8 100%) !important;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  border: 1px solid #dde3e8;
}

.rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
  border-color: none;
  background: linear-gradient(91.96deg, #3ee6d5 -13.02%, #0ecab8 100%) !important;
}

.rs-calendar-month-dropdown-year-active {
  color: #0ecab8;
}

.rs-calendar-show-month-dropdown .rs-calendar-header-title-date {
  color: #0ecab8;
}

.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
  background-color: #0ecab8 !important;
  border-radius: 5px;
  margin: 0 5px;
}

.rs-calendar-time-dropdown-column-title {
  font-weight: bold;
  background-color: #fff;
  color: #252f35;
}

.rs-calendar-show-time-dropdown .rs-calendar-header-title-time {
  color: #0ecab8;
  font-weight: bold;
}

.rs-picker-toolbar {
  border-top: none;
}

.rs-modal-backdrop.in {
  opacity: 0.5;
}

.rs-modal-backdrop {
  background-color: #000000;
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-active,
.rs-picker-select-menu-item.rs-picker-select-menu-item-active:hover {
  color: #0ecab8;
  background-color: #f6fffe;
}

.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus,
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:hover {
  border-color: #0ecab8;
  outline: 0;
}

.rs-picker-select-menu-item:not(.rs-picker-select-menu-item-disabled):hover,
.rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
  background-color: #f6fffe;
}

.rs-calendar-time-dropdown-cell:hover {
  background-color: #f6fffe;
}
