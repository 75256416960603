// Bottom
@for $i from 1 through 5 {
  .s-mb__#{$i} {
    margin-bottom: $base * $i;
  }

  .s-mt__#{$i} {
    margin-top: $base * $i;
  }

  .s-ml__#{$i} {
    margin-left: $base * $i;
  }

  .s-mr__#{$i} {
    margin-right: $base * $i;
  }

  .s-my__#{$i} {
    margin-top: $base * $i;
    margin-bottom: $base * $i;
  }

  .s-mx__#{$i} {
    margin-left: $base * $i;
    margin-right: $base * $i;
  }
}

.s-my__auto {
  margin-left: auto;
  margin-right: auto;
}
