/* NOTES: SCSS Rules
 * 1. Don't use css, use scss instead
 * 2. Prioritize to use styled Component
 * 3. Use prefix skul- if need to create custom css name here
 * 4. If not for global css, write scss separate in component folder, and add prefix s-
 * 5. Don't replace css for rsuite except for global css like modals calender, etc or use classPrefix or class name or passing rsuite components to styled component & write custom css there instead
 */

@import './_variables.scss';
@import './_breakpoints.scss';
@import './_display.scss';
@import './_margin_padding.scss';
@import './_rsuite_custom.scss';
@import './_tabs.scss';

html,
body {
  font-family: 'Open Sans', sans-serif;
  color: $dark-gray;
  font-size: 13px;
  height: 100%;
}

.rs-content,
.skul-scrollbar {
  @media screen and (min-width: 1200px) {
    scrollbar-width: thin;
    scrollbar-color: #d9dcdf #fff;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: white;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d9dcdf;
      border-radius: 20px;
    }
  }
}

@media screen and (min-width: 1024px) {
  html,
  body {
    font-size: 15px;
  }
}

button {
  &:focus {
    outline: none;
  }

  .max-w-1440 {
    max-width: 1440px;
    margin: 0 auto;
  }

  .s-text-error {
    color: #e00000;
  }
}

// breaking rule number 3
.s-btn-back {
  box-shadow: none !important;
  height: auto !important;
  margin-bottom: 10px;
  padding: 0px;
  color: #959595;

  &:hover {
    text-decoration: none;
  }
}

.skul-max__width {
  max-width: 1115px;
  margin: 0 auto;
}

.skul-link__pink {
  color: #e8285c;
  font-weight: 400;
  font-size: 13px;

  &:hover,
  &:focus,
  &:active {
    color: #e8285c;
    text-decoration: none;
  }
}

.skul-link__normal {
  color: inherit;

  &:hover,
  &:focus,
  &:active {
    color: inherit;
    text-decoration: none;
  }
}

.skul-text__pink {
  color: #e8285c;
}

// breaking rule number 2
.text-pink {
  color: $pink;

  &:hover,
  &:focus,
  &:active {
    color: $pink;
  }
}

.interest-title-wrapper {
  background: #ffe7d8;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-top: 7px;

  .interest-title {
    font-size: 13px;
    color: #424d55;
    padding-left: 12px;
    padding-right: 12px;
  }

  .interest-point {
    height: 50px;
    line-height: 50px;
    padding-left: 12px;
    padding-right: 18px;
    border-left: 2px dashed white;
    white-space: nowrap;
    color: #f45e3a;
    font-weight: bold;
    font-size: 15px;
    position: relative;
    display: flex;
    align-items: center;

    &::after {
      content: '';
      width: 18px;
      height: 18px;
      border-radius: 8px;
      background-color: white;
      display: block;
      position: absolute;
      right: -12px;
      top: 17px;
    }
  }
}

.interest-box-wrapper {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  .interest-box {
    span {
      display: block;
      height: 38px;
      border: 1px solid #dde3e8;
      border-radius: 5px;
      font-size: 13px;
      line-height: 38px;
      text-align: center;
      color: $dark-gray;
      text-transform: capitalize;
      cursor: pointer;
    }

    input[type='checkbox'] {
      display: none;
    }

    input[type='checkbox']:checked ~ span {
      background: linear-gradient(93.68deg, #fe8e4f 5.01%, #ec5c83 100%);
      border-color: transparent;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
      color: white;
      font-weight: 700;
    }
  }
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.548) !important;
  animation: react-confirm-alert-fadeIn 0ms 0ms forwards !important;
}

.skul-modal {
  &__image {
    max-width: 300px;
    margin: 0 auto;
    height: 100vh;
    display: flex !important;
    justify-content: center;
    align-items: center;

    .bounce-in,
    .bounce-out {
      animation-timing-function: ease-in-out;
    }

    .rs-modal-content {
      padding: 0;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      overflow: hidden;
    }
  }

  &__transparent {
    .rs-modal-content {
      background: transparent;
      box-shadow: none;
      overflow: visible;
    }
  }
}

.skul-btn {
  width: 100%;
  font-weight: 700;
  margin-bottom: 1rem;
  height: 46px;
  text-transform: uppercase;
  font-size: 15px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  &.rs-btn-disabled {
    opacity: 0.7;

    &:hover {
      opacity: 0.7;
    }
  }
}

.skul-btn__red {
  background: linear-gradient(91.96deg, #e50078 -13.02%, #ca0e0e 100%);
  color: #ffffff !important;

  &:active,
  &:hover,
  &:active:hover,
  &:focus {
    color: #ffffff;
    background: linear-gradient(91.96deg, #e50078 -13.02%, #ca0e0e 100%);
  }
}

.skul-btn__green {
  color: #ffffff;
  background: linear-gradient(91.96deg, #3ee6d5 -13.02%, #0ecab8 100%);

  &:active,
  &:hover,
  &:focus,
  &:active:hover {
    color: #ffffff;
    background: linear-gradient(91.96deg, #3ee6d5 -13.02%, #0ecab8 100%);
  }
}

.rs-btn-subtle.rs-btn-disabled.skul-btn__green,
.rs-btn-subtle.rs-btn-disabled.skul-btn__green:hover,
.rs-btn-subtle.rs-btn-disabled.skul-btn__green:focus,
.rs-btn-subtle.rs-btn-disabled.skul-btn__green.focus,
.rs-btn-subtle.rs-btn-disabled.skul-btn__green:active,
.rs-btn-subtle.rs-btn-disabled.skul-btn__green:hover:active {
  background: linear-gradient(91.96deg, #3ee6d5 -13.02%, #0ecab8 100%);
  opacity: 0.7;
  color: #ffffff;
}

.rs-btn-subtle.rs-btn-disabled.skul-btn__red,
.rs-btn-subtle.rs-btn-disabled.skul-btn__red:hover,
.rs-btn-subtle.rs-btn-disabled.skul-btn__red:focus,
.rs-btn-subtle.rs-btn-disabled.skul-btn__red.focus,
.rs-btn-subtle.rs-btn-disabled.skul-btn__red:active,
.rs-btn-subtle.rs-btn-disabled.skul-btn__red:hover:active {
  background: linear-gradient(91.96deg, #e50078 -13.02%, #ca0e0e 100%);
  opacity: 0.7;
}

div[class^='Snackbar_snackbar-wrapper'] {
  margin: 0;
  bottom: 0px !important;
  z-index: 9;
}

@media (min-width: 1024px) {
  div[class^='Snackbar_snackbar-wrapper'] {
    bottom: 8px !important;
  }
}

.skul-border__error {
  border: 1px solid red !important;
}

.skul-border__errorUpload {
  .rs-uploader-trigger.rs-uploader-trigger-customize {
    border: 1px solid red !important;
    border-radius: 5px;
  }
}

.skul-modal__miniCenter {
  max-width: 350px;
  margin: 0 auto;
  height: 100vh;
  display: flex !important;
  justify-content: center;
  align-items: center;

  .rs-modal-body {
    padding: 0;
    margin-bottom: 28px;
    margin-top: 0;
    display: grid;
    place-items: center;
  }

  .rs-modal-dialog {
    width: clamp(310px, 310px, 350px);
  }

  .rs-modal-content {
    padding: 24px ​16px;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  }

  .rs-modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and(min-width: 1024px) {
    width: 535px;
    max-width: 535px;

    .rs-modal-dialog {
      width: 535px;
    }
  }
}

.skul-custom__gender {
  height: 48px;
  width: 100%;
  background: #ecf1f4;
  border: medium none !important;
  display: grid !important;
  grid-template-columns: repeat(2, auto);
  border-radius: 5px !important;

  .rs-radio-inline {
    .rs-radio-checker {
      height: 100%;
      width: 100%;
    }

    label {
      height: 100%;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      transition: none !important;
    }
  }

  .rs-radio.rs-radio-inline.rs-radio-checked label {
    color: #fff !important;
    font-size: 0.95rem !important;
    background: #a1aeb7;
    border-radius: 5px;
  }
}

.skul-modal__miniCenterTwoButton {
  max-width: 350px;
  margin: 0 auto;
  height: 100vh;
  display: flex !important;
  justify-content: center;
  align-items: center;

  .rs-modal-body {
    padding: 0;
    margin-bottom: 28px;
    margin-top: 0;
    display: grid;
    place-items: center;
  }

  .rs-modal-dialog {
    width: clamp(310px, 310px, 350px);
  }

  .rs-modal-content {
    padding: 24px ​16px;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  }

  @media screen and(min-width: 1024px) {
    width: 535px;
    max-width: 535px;

    .rs-modal-dialog {
      width: 535px;
    }
  }
}

.skul-modal__bottom {
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: flex-end;
  height: 100vh;

  .rs-modal-dialog {
    width: 100%;
    margin-bottom: 400px;
    scroll-snap-type: y mandatory;
  }

  .rs-modal-content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -400px;
    height: 100vh;
    scroll-snap-align: start;
    border-radius: 6px 6px 0 0;
  }

  .rs-modal-body {
    position: relative;
    margin-top: 0px;
    padding-bottom: 0px;
  }

  @media screen and (min-width: 1024px) {
    width: 535px;
    max-width: 535px;
    align-items: center;

    .rs-modal-dialog {
      width: 535px;
      margin-bottom: 0px;
      scroll-snap-type: y mandatory;
    }

    .rs-modal-content {
      position: relative;
      left: 0;
      right: 0;
      bottom: 0;
      height: auto;
      scroll-snap-align: start;
    }
  }
}

.skul-loader__pink {
  .rs-loader-spin::after {
    border-top-color: rgb(237, 82, 120) !important;
  }

  .rs-loader-spin::before {
    border: 3px solid rgba(237, 82, 120, 0.4) !important;
  }

  .rs-loader-spin + .rs-loader-content {
    margin-left: 10px !important;
  }
}
